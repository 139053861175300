a.accept-button,
a.style-button {
    cursor: pointer;
    margin: 10px 0 !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-family: Antonio-Regular, sans-serif;
    letter-spacing: .03125rem;
    font-size: 1rem;
    display: flex;
    font-weight: 400;
    line-height: 1;
    padding: 11px 25px;
    background-color: #fff;
    color: #e20101;
    text-decoration: none;
    border: 1px solid #e20101;
    outline: none;
}

a.accept-button {
    color: #048767;
    border: 1px solid #048767;
}

a.style-button::after {
    content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MyAyMiI+PHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZTIwMTAxIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik0wIDExaDYxLjVNNTEuMi43MUw2MS41OCAxMSA1MS4yIDIxLjI5Ii8+PC9zdmc+);
    display: block;
    margin-left: 12px;
    width: 2rem;
}